.cover {
    background-image: url('../../images/homepage-cover.png');
    background-size: cover;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: end;


    #video-container {
        width: 100%;
    }
    
    #non-stop-video {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Maintain aspect ratio and cover the entire frame */  
    }

    & .text {
        position: absolute;
        text-align: end;
        color: white;
        width: 100%;
        text-align: center;
        z-index: 1;
        padding: 0% 0% 7% 0%;
        
        h1 { -webkit-text-stroke: 2px black; }
        
        h3{
            -webkit-text-stroke: 1px black;
            color: var(--third-text);
        }

        label {
            font-size: 30px;
        }
    }

    & #buttons {
        padding: 20px 0px 0px 0px;

        #button{
            background-color: var(--primary-blue);
            color: var(--primary-white-text);
            border-radius: 16px;
            width: 150px;
            cursor: pointer;
        }
    }
}

#intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--primary-blue);
    color: var(--primary-white);
    z-index: -1;
    padding: 0px 0px 50px 0px;

    & .wp-block-group__inner-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px 20px 20px 20px;
        text-align: center;

        & h2 {
            margin: 0px;
            padding: 20px 0px;
            color: var(--primary-gold-text);
            font-weight: 100;
        }

        & .buttons {
            display: flex;
            justify-content: center;
        }
    }
}

#client-section {
    background-color: var(--primary-blue);
    color: var(--primary-white-text);
    padding: 50px 10%;

    .wp-block-group__inner-container{
        display: flex;
        flex-direction: column;
        gap: 40px;
        h2{
            text-align: center;
        }
    
        #client {
            .wp-block-group__inner-container{
                #description {
                    .wp-block-group__inner-container {
                        display: flex;
                        flex-direction: column;
                        gap: 40px;
        
                        p {
                            margin: 0px;
                        }
                    }
                }    
            }
        }
    
    }
}

.virtual-tour {
    background-color: var(--primary-blue);
    color: var(--primary-white-text);
    padding: 50px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    #buttons{
            button{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 250px;
                height: 60px;
                border-radius: 10px;
                background: var(--primary-gold);
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
                border: 3px solid var(--primary-white);
                cursor: pointer;
        }
    }

}

#buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.partners-container {
    background-color: var(--primary-blue);

    & .partners-cover {
        background-image: url("../../images/trusted-partners-cover.png");
        width: 100%;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position-y: top;
        opacity: 0.7;
        height: 400px;
    }

    & .title {
        background-color: var(--primary-white);
        display: flex;
        justify-content: center;
        padding: 30px 0px;

        & .partners-heading {
            width: 65%;
            height: 25px;
            background-color: var(--primary-gold);
            display: flex;
            justify-content: center;
            align-content: center;
            border-radius: 50%;

            & h2 {
                display: flex;
                align-items: center;
                color: var(--primary-blue-text);
            }
        }
    }

    & .partners {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: var(--primary-white);
        gap: 30px;
        padding: 0px 0px 50px 0px;

        & .boral {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 50px;
            font-weight: 800;
        }

        & .colourbond {
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            font-size: 40px;
            font-weight: 500;
        }

        & .bos-tvd {
            display: flex;
            flex-direction: column;

            & .bos {
                font-size: 50px;
                text-align: center;
            }

            & .tvd {
                font-size: 30px;
            }
        }

        & .kleenmaid {
            font-size: 50px;
            font-weight: 300;
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        }

        & .bricks {
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            font-size: 50px;
            font-weight: 300;
        }

        & .flooring {
            font-size: 50px;
            font-weight: 400;
        }

        & .tile-factory {
            display: flex;
            flex-direction: column;
            font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            font-weight: 900;

            & .tfo {
                font-size: 50px;
                text-align: center;
            }

            & .tile {
                font-size: 30px;
            }
        }

        & .dulux {
            font-size: 50px;
            font-weight: 900;
            text-decoration: underline;
        }
    }
}

@media (max-width: 1220px) {
    .partners-container {
        & .title {
            & .partners-heading {
                width: 70%;

                & h1 {
                    font-size: 35px;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .cover{
        position: inherit;    
    }  
}

@media (max-width: 760px) {    
    .cover{
        .text{
            padding: 0% 0% 20% 0%;
        }
    }
    .partners-container {
        & .title {
            & .partners-heading {
                width: 100%;

                & h1 {
                    font-size: 25px;
                }
            }
        }
    }
}